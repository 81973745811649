import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

const useSynchronisedCarousel = (
  width: number,
  carouselRef: RefObject<HTMLDivElement>
) => {
  const scrollStateRef = useRef({
    prevButtonStates: [true, false],
    previousScrollLeft: 0,
  });

  const [
    [leftButtonDisabled, rightButtonDisabled],
    setButtonDisabled,
  ] = useState([true, false]);

  /**
   * Update scroll position reference and button state when needed.
   */
  const syncScrollState = useCallback(() => {
    const scrollLeft = carouselRef.current?.scrollLeft ?? 0;

    // ignore iteration as it's the same as the previous
    if (scrollLeft === scrollStateRef.current.previousScrollLeft) return;

    // update current iteration scrollLeft
    scrollStateRef.current.previousScrollLeft = scrollLeft;

    // abort if we don't have enough information for the button state
    if (!carouselRef.current) return;

    const { scrollWidth, clientWidth } = carouselRef.current;

    // compute button states
    const shouldDisableLeftButton = scrollLeft <= 20;
    const shouldDisableRightButton = scrollLeft >= scrollWidth - clientWidth;

    // update the buttons states (only) if needed
    const nextButtonStates: [boolean, boolean] = [
      shouldDisableLeftButton,
      shouldDisableRightButton,
    ];
    if (
      !nextButtonStates.every(
        (state, index) =>
          state === scrollStateRef.current.prevButtonStates[index]
      )
    ) {
      scrollStateRef.current.prevButtonStates = nextButtonStates;
      setButtonDisabled(nextButtonStates);
    }
  }, [carouselRef]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', syncScrollState);
      return () => carousel.removeEventListener('scroll', syncScrollState);
    }
  }, [carouselRef, syncScrollState]);

  const carouselNavigation = (direction: 'previous' | 'next') => {
    if (carouselRef.current) {
      const nextScrollLeft =
        scrollStateRef.current.previousScrollLeft +
        (direction === 'previous' ? -1 : 1) * (width + 24);
      carouselRef.current.scrollTo({
        left: nextScrollLeft,
        behavior: 'smooth',
      });
      scrollStateRef.current.previousScrollLeft = nextScrollLeft;
    }
  };

  return { leftButtonDisabled, rightButtonDisabled, carouselNavigation };
};

export default useSynchronisedCarousel;
